.guide-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    .top-box {
        width: 100%;
        height: 250px;
        display: flex;
        align-items: center;
        background-image: url('../images/topBanner.jpg');
        background-size: cover;
        background-position: center;
        padding-left: 30px;
        p {
            font-size: 18px;
            font-weight: 600;
            color: #000;
            line-height: 25px;
            margin-bottom:0px;
        }
    }
    .bottom-box{
        position: absolute;
        margin-top: 230px;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 8px;
        padding-top: 20px;
        .money-box {
            width: 100%;
            height: 108px;
            margin-bottom: 12px;
            padding-right: 12px;
            display: flex;
            .content-box {
                flex: 1;
                background-image: url('../images/money.png');
                background-size: cover;
                background-position: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                .title {
                    font-size: 20px;
                    color: #1CAEFB;
                }
            }
        }
        .function-box {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .content-box {
                width: 50%;
                height: 70px;
                margin-bottom: 10px;
                padding-right: 10px;
                display: flex;
                .text {
                    color: #fff
                }
                .inner-content-box-info {
                    flex: 1;
                    border-radius: 10px;
                    padding-left: 14px;
                    padding-right: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: linear-gradient(114deg, #02CB9A 0%, #C5F7E9 100%);
                    .info-image {
                        background-image: url('../images/info.png');
                        background-size: cover;
                        background-position: center;
                        width: 38px;
                        height: 35px;
                    }
                }
                .inner-content-wgk-info {
                    flex: 1;
                    border-radius: 10px;
                    padding-left: 14px;
                    padding-right: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: linear-gradient(113deg, #62B7F4 0%, #D8EDFC 98%);
                    .wgk-image {
                        background-image: url('../images/wgk.png');
                        background-size: cover;
                        background-position: center;
                        width: 32px;
                        height: 37px;
                    }
                }
                .inner-content-salary-info {
                    flex: 1;
                    border-radius: 10px;
                    padding-left: 14px;
                    padding-right: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: linear-gradient(113deg, #FFB200 1%, #FFE292 100%);
                    .salary-image {
                        background-image: url('../images/salaryImage.png');
                        background-size: cover;
                        background-position: center;
                        width: 40px;
                        height: 37px;
                    }
                }
                .inner-content-contract-info {
                    flex: 1;
                    border-radius: 10px;
                    padding-left: 14px;
                    padding-right: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: linear-gradient(113deg, #AE97FF 0%, #FCBAFF 100%);
                    .contract-image {
                        background-image: url('../images/contract.png');
                        background-size: cover;
                        background-position: center;
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }
    }
}

@primary-color: #1BADFB;@error-color: #DE1717;@heading-color: #002247;@text-color: #002247;@text-color-secondary: #6D7986;@border-color-base: #F5F8FB;@border-radius-base: 12px;@btn-shadow: 0 2px 7px rgba(0, 0, 0, 0.04);@btn-primary-shadow: 0 3px 8px rgba(27, 173, 251, 0.28);@btn-height-base: 40px;@btn-height-lg: 46px;@btn-height-sm: 34px;@btn-default-color: #737E8B;@btn-default-bg: #EBECF0;@input-height-lg: 52px;